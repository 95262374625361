<template>
	<div class="enterprise">
		<div class="enterprise_left">
			<div>
				<img class="logo_img" :src="company_remark">
					<span v-if="header_style.title_type==1" class="logo_text" :style="{'font-size':header_style.size+'px'}">{{header_style.company_name}}</span>
				<span v-else class="logo_text" :style="{'font-size':header_style.size+'px','color':header_style.color}">{{title_name}}</span>
			</div>
			<div class="conteont">
				内容区域
			</div>
		</div>
		<div class="enterprise_right">
			<p class="header_style_p">企业标识</p>
			<img  class="logo_img" :src="company_remark">
			 <p style="margin-top: 20px;" @click="mergeVisible=true">
				 <a-button type="primary" >点击上传</a-button>
			 </p>
			 
			<a-form :model="header_style" :label-col="labelCol" :wrapper-col="wrapperCol">
				<p class="header_style_p" style="margin-top: 16px;">企业名称</p>
				<a-form-item label="">
					<p>	
					 <a-radio-group style="width: 100%;" v-model:value="header_style.title_type">
					        <a-radio-button style='width: 50%;' :value="1">固定文字</a-radio-button>
					        <a-radio-button style='width: 50%;'  :value="2">魔法变量</a-radio-button>
					      </a-radio-group>
					</p>
					<p v-if="header_style.title_type==1">
						<a-input v-model:value="header_style.company_name" />
					</p>
					<p v-else>
						<a-select v-model:value="header_style.title" @change='changeOp($event)' placeholder="请选择变量">
							<a-select-option v-for="(item,index) in select_list" :value="item.value">{{item.label}}</a-select-option>
						</a-select>
					</p>
				</a-form-item>
				<p>文字颜色</p>
				<a-form-item >
					<a-input type="color" v-model:value="header_style.color" />
				</a-form-item>
				<p>文字字号</p>
				<a-form-item >
					<a-input type='number' style='width: 100%;' id="inputNumber"  v-model:value="header_style.size" :min="12" :max="48" />
				</a-form-item>
			</a-form>
		</div>
		<div>
			<a-drawer placement="bottom" height="70" :mask="false" :visible="batchDeleteVisible" :closable="false"
			  >
			    <a-button type="primary" class='pointerCancel' style="margin-left:50%;" @click='add'>保存</a-button>
			</a-drawer>
		</div>
		<a-modal width="63%" :footer='null'  v-model:visible="mergeVisible" >
		  <div class="col-md-6" ref="imgCutterBox" style="height: 420px; overflow: hidden">
		      <ImgCutter
			  :drawImg='drawImg'
		          v-if="refresh"
		          ref="imgCutterModal"
		          :crossOrigin="params.crossOrigin"
		          :label="params.label"
		          :rate="params.rate"
		          :index="params.index"
		          :isModal="params.isModal"
		          :showChooseBtn="params.showChooseBtn"
		          :lockScroll="params.lockScroll"
		          :boxWidth="params.boxWidth"
		          :boxHeight="params.boxHeight"
		          :cutWidth="params.cutWidth"
		          :cutHeight="params.cutHeight"
		          :sizeChange="params.sizeChange"
		          :moveAble="params.moveAble"
		          :imgMove="params.imgMove"
		          :tool="params.tool"
		          :fileType="params.fileType"
		          :originalGraph="params.originalGraph"
		          :previewMode="params.previewMode"
		          :toolBoxOverflow="params.toolBoxOverflow"
		          :WatermarkText="params.WatermarkText"
		          :WatermarkTextFont="params.WatermarkTextFont"
		          :WatermarkTextColor="params.WatermarkTextColor"
		          :WatermarkTextX="params.WatermarkTextX"
		          :WatermarkTextY="params.WatermarkTextY"
		          :smallToUpload="params.smallToUpload"
		          :saveCutPosition="params.saveCutPosition"
		          :scaleAble="params.scaleAble"
		          :DoNotDisplayCopyright="false"
		          toolBgc="params.toolBgc"
		          @onChooseImg="onChooseImg"
		          @onClearAll="onClearAll"
		          @onPrintImg="onPrintImg"
		          @error="catchError"
		          @cutDown="cutDown"
				  @confirm='confirmBtm'
		      >
		         <!-- <template #open></template>
		          <template #choose v-if="params.isModal == false"> </template>
		          <template #cancel v-if="params.isModal == false"> </template>
		          <template #confirm v-if="params.isModal == false"> </template> -->
		      </ImgCutter>
		  </div>
		                     
		</a-modal>
	</div>
</template>

<script>
	import {
		getHeaderSelect,saveHeaderStyle,getHeaderDetail
	} from '@/api/enterprise.js'
	import {
		upload,
	} from '@/api/public'
	import {
		message,
	} from 'ant-design-vue';
import ImgCutter from '@/components/imgCurre/ImgCutter.vue';
	export default {
		components: {
			ImgCutter
		},
		data() {
			return {
				mergeVisible:false,
				form: {
				    list: [{
				        type: 'upload',
				        name: 'logo',
				        value: '',
				        limit: 1
				    }]
				},
				visible:false,
				select_list:[],
				batchDeleteVisible:true,
				  "company_remark":"",//企业标识
				    "header_style":{
				        "header":1,
				        "title_type":1,//1固定文本 2魔法变量
				        "title":1,//文字
				        "color":"#11111",
				        "font":1,
				        "size":24
				    },
				all_list: [],
				visit2: true,
				labelCol: {
					span: 8
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 24
				},
				select_list: [],
				type: '',
				title_name:'',
				cutImgSrc: '',
				cutImgWidth: 250,
				cutImgHeight: 250,
				imgSrc: null,
				downloadName: '',
				refresh: true,
				isForIe9: false,
				loadImg: false,
				onPrintImgTimmer: null,
				params: {
				    label:'’',
				    fileType: 'jpeg',
				    crossOrigin: true,
				    crossOriginHeader: '*',
				    rate: '',
				    toolBgc: 'none',
				    isModal: false,
				    showChooseBtn: true,
				    saveCutPosition: true,
				    scaleAble: true,
				    lockScroll: true,
				    toolBoxOverflow: true,
				    quality: 1,
				    boxWidth: 800,
				    boxHeight: 358,
				    cutWidth: 576,
				    cutHeight: 230,
				    sizeChange: true,
				    moveAble: true,
				    imgMove: true,
				    tool: true,
				    originalGraph: false,
				    WatermarkText: 'vue-img-cutter',
				    WatermarkTextFont: '12px Sans-serif',
				    WatermarkTextColor: '#00ff00',
				    WatermarkTextX: 0.95,
				    WatermarkTextY: 0.95,
				    smallToUpload: true,
				    previewMode: true,
				    index: '',
				},
				drawImg:{img: 'https://crm-img.waakuu.com/cq2dev.52shanglu.com/manage/20230419/6ba28915f46c6467/80e3abecb80a78f2.jpg?x-oss-process=style/visit', //规定要使用的图像、画布或视频
				sx: 200, //开始剪切的 x 坐标位置
				sy: 200, //开始剪切的 y 坐标位置
				swidth: 200, //被剪切图像的宽度
				sheight: 300, //被剪切图像的高度
				x: 200, //在画布上放置图像的 x 坐标位置
				y: 200, //在画布上放置图像的 y 坐标位置
				width: 200, //要使用的图像的宽度
				height: 200, //要使用的图像的高度
				    }           
			}

		},
		created() {
			this.type = this.$route.query.type
			this.getIndustry()
		},
		mounted() {
		    this.createCode();
		},
		methods: {
			confirmBtm(){},
			
			cutDown(res) {
				let that = this
			    this.imgSrc = res.dataURL;
			    this.downloadName = res.fileName;
			this.$req({
				mode: 'file',
				url: '/manage/api.plugs/upload',
				data: {
					file: res.file,
				}
			}).then(res2 => {
					that.company_remark = res2.data.upload_data.url
					that.mergeVisible = false
			}).catch(e => {
			})
				// upload({
				// 	data: {
				// 		file: res.file,
				// 	}
				// }).then(res => {
			
				// })
				
			},
			forIe9: function () {
			    this.$refs.imgCutterModal.handleOpen({
			        name: this.cutImgSrc,
			        src: this.cutImgSrc,
			    });
			},
			doRefresh: function () {
			    this.refresh = false;
			    this.$nextTick(() => {
			        this.refresh = true;
			    });
			},
			onClearAll: function () {
			    // 清空事件
			    this.loadImg = false;
			    this.imgSrc = '';
			},
			onPrintImg: function (res) {
			    // 预览图片
			    this.imgSrc = res.dataURL;
			},
			onChooseImg: function (res) {
			    // 选择图片事件
			    if (res) {
			        this.loadImg = true;
			    }
			},
			createCode: function () {
			   
			},
			setData: function ($event) {
			    function isNumber(val) {
			        let regPos = /^\d+(\.\d+)?$/; //非负浮点数
			        let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
			        if (regPos.test(val) || regNeg.test(val)) {
			            return true;
			        } else {
			            return false;
			        }
			    }
			
			    let value = $event.target.value;
			    if (value == 'true') {
			        value = true;
			    }
			    if (value == 'false') {
			        value = false;
			    }
			    if (isNumber(value) === true) {
			        value = parseInt(value);
			    }
			    this.params[$event.target.name] = value;
			    this.doRefresh();
			    this.createCode();
			},
			catchInput(e) {
			    let val = e.target.value;
			    let key = e.target.name;
			    this[key] = val;
			},
			catchError(res) {
			    console.log(res);
			    window.alert(res.msg);
			},
			pluginExe: function (functionName) {
			    this.$refs.imgCutterModal[functionName]();
			},
			img_add(e){
				this.company_remark = e.img.url
			},
			add(){
				saveHeaderStyle({data:{
					company_remark:this.company_remark,
					header_style:this.header_style
				}}).then(res => {
					this.$router.back()
					this.getIndustry()
				})	
			},
			getIndustry() {
				getHeaderDetail().then(res => {
					this.header_style =  res.data.list.header_style
					this.company_remark   = res.data.list.company_remark
					this.drawImg.img = res.data.list.company_remark
					this.form.list[0].value  = res.data.list.company_remark
				})
				getHeaderSelect().then(res => {
					this.select_list = res.data.title
					this.title_name = res.data.title[0].label
				})
			},
			changeOp(e) {
				this.select_list.forEach(item=>{
					if(item.value==e){
						this.title_name= item.label
					}
				})
			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.enterprise {
		margin: 16px;
		display:flex;
	}

	.enterprise_left {
		border: 1px solid @cq_line_e;
		height: 90vh;
		flex: 3;
		padding: 32px;
	}

	.enterprise_right {
		border: 1px solid @cq_line_e;
		margin-left: 20px;
		padding: 16px;
		flex: 1;
	}
	.logo_img{
		    max-width: 200px;
		    max-height: 80px;
		    margin-right: 10px;
	}
	.logo_text{
		    font-weight: bolder;
		    word-break: break-all;
		    color: rgb(0, 0, 0);
		    font-size: 24px;
	}
	.conteont{
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    width: 100%;
		    height: 85%;
		    color: #bbbec4;
		    font-size: 24px;
		    background: #f0f4f8;
			margin-top: 20px;
			
	}
	.header_style_p{
		margin-bottom: 12px;
		    color: #1c2438;
		    font-size: 16px;
	}
	


</style>
